import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { ApiResponse, ApiResponsePageable, DeleteApiResponse, PageControl } from '@models/application';
import { User, UserStatus } from '@models/user';
import { Utils } from '@shared/utils';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private readonly _http: HttpClient
  ) { }

  public getUsers(pageControl: PageControl, filters?): Observable<ApiResponsePageable<User>> {
    const paginate = Utils.mountPageControl(pageControl);
    const filterParams = Utils.mountPageControl(filters);

    return this._http.get<ApiResponsePageable<User>>(`${environment.api}/user/search?${paginate}${filterParams}`);
  }

  public getUser(): Observable<ApiResponse<User>> {
    return this._http.get<ApiResponse<User>>(`${environment.api}/user/me`);
  }

  public getUserById(id: string): Observable<ApiResponse<User>> {
    return this._http.get<ApiResponse<User>>(`${environment.api}/user/${id}`);
  }

  public postUser(user: FormData): Observable<ApiResponse<User>> {
    return this._http.post<ApiResponse<User>>(`${environment.api}/user/`, user);
  }
  // return this._http.post<ApiResponse<User>>(`${environment.api}/open/user/${token}`, user);

  public patchUser(id: string, user: FormData): Observable<ApiResponse<User>> {
    return this._http.post<ApiResponse<User>>(`${environment.api}/user/${id}?_method=PATCH`, user);
  }

  public deleteUser(id: number): Observable<DeleteApiResponse> {
    return this._http.delete<DeleteApiResponse>(`${environment.api}/user/${id}`);
  }

  public checkToken(token: string): Observable<ApiResponse<string>> {
    return this._http.post<ApiResponse<string>>(`${environment.api}/user/check-token/`, {token});
  }

  public recoverPassword(email: string): Observable<ApiResponse<string>> {
    return this._http.post<ApiResponse<string>>(`${environment.api}/user/recover-password/`,  email);
  }

  public resetPassword(token: string, password : string): Observable<ApiResponse<string>> {
    return this._http.post<ApiResponse<string>>(`${environment.api}/user/reset-password/${token}`,  {password} );
  }

  public autoResetPassword(old_password : string, new_password : string): Observable<ApiResponse<string>> {
    return this._http.post<ApiResponse<string>>(`${environment.api}/user/reset-password`,  {old_password, new_password} );
  }

  // Métodos não utilizados

  public updateStatus(id: string, newStatus: UserStatus): Observable<ApiResponse<User>> {
    return this._http.patch<ApiResponse<User>>(`${environment.api}/user/status/${id}`, {status: newStatus});
  }

  public inviteUser(email: string, cellphone: number): Observable<ApiResponse<string>> {
    return this._http.post<ApiResponse<string>>(`${environment.api}/user/invite`, {email: email, cellphone: cellphone});
  }


  public validateCode(code : string) : Observable<ApiResponse<string>> {
    return this._http.post<ApiResponse<string>>(`${environment.api}/validate-code?_method=GET`, {code})
  }

  public validateToken(token : string) : Observable<ApiResponse<string>> {
    return this._http.post<ApiResponse<string>>(`${environment.api}/open/user/check-token`, {token: token})
  }

}
