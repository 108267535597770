import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Account } from '@models/account';
import { ApiResponse, ApiResponsePageable, DeleteApiResponse, PageControl } from '@models/application';
import { Utils } from '@shared/utils';
import { Observable } from 'rxjs';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  private sessionEndpoint: string = 'account';

  constructor(
    private readonly _http: HttpClient,
    private readonly _storage : LocalStorageService
  ) { }

  public getAccounts(pageControl?: PageControl, filters?): Observable<ApiResponsePageable<Account>> {

    const paginate = Utils.mountPageControl(pageControl);
    const filterParams = Utils.mountPageControl(filters);

    return this._http.get<ApiResponsePageable<Account>>(`${environment.api}/${this.sessionEndpoint}/search?${paginate}${filterParams}`);
  }

  public postAccount(account: FormData): Observable<ApiResponse<Account>> {
    return this._http.post<ApiResponse<Account>>(`${environment.api}/${this.sessionEndpoint}`, account);
  }

  public getActualAccount(): Observable<ApiResponse<Account>> {
    return this._http.get<ApiResponse<Account>>(`${environment.api}/${this.sessionEndpoint}/${this._storage.get('account_id').toString()}`);
  }

  public getAccountById(id: string): Observable<ApiResponse<Account>> {
    return this._http.get<ApiResponse<Account>>(`${environment.api}/${this.sessionEndpoint}/${id}`);
  }

  public patchAccount(id: string, account: Account): Observable<ApiResponse<Account>> {
    return this._http.patch<ApiResponse<Account>>(`${environment.api}/${this.sessionEndpoint}/${id}`, account);
  }

  public deleteAccount(id: string): Observable<DeleteApiResponse> {
    return this._http.delete<DeleteApiResponse>(`${environment.api}/${this.sessionEndpoint}/cancel/${id}`);
  }

}
